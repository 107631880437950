<template>
  <v-row class="px-md-6 py-md-6">
    <v-col cols="12">
      <div
        class="content-wrap px-5 py-5"
        :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-0'"
      >
        <v-row>
          <v-col class="justify-space-between d-flex" cols="12">
            <div class="d-flex align-center">
              <v-img max-width="30" :src="require('@/assets/spending.svg')" />
              <block-text
                :text="$t('activities.mySpendings')"
                color="primary"
                type="title"
                class="ml-3"
              />
            </div>
            <div
              class="d-flex align-center cursor-pointer"
              @click="goToWallet()"
            >
              <block-text
                :text="$t('activities.showLess')"
                color="primary"
                weight="bold"
                type="body-1"
                class="nav-link mx-1"
              />
              <ph-x :size="16" :color="$vuetify.theme.dark ? '#fff' : '#222'" />
            </div>
          </v-col>
          <v-col cols="12">
            <v-row class="d-flex align-center justify-space-between mb-0">
              <v-col
                lg="8"
                xs="12"
                xl="10"
                class="flex-grow-1 flex-shrink-0 flex-auto order-2 order-lg-1 d-flex align-center py-0 py-sm-1 py-md-2"
              >
                <activity-filters
                  :direction="'outgoing'"
                  :filters="outgoingFilters"
                  :isMobileOrTablet="isMobileOrTablet"
                />
              </v-col>
              <v-col
                sm="4"
                lg="4"
                xl="2"
                xs="12"
                class="d-flex align-center justify-end order-1 order-lg-2 py-0 ml-auto"
              >
                <activity-download :direction="'outgoing'" />
                <date-filter :direction="'outgoing'" />
              </v-col>
            </v-row>
            <activity-iterator
              v-if="isMobileOrTablet"
              :pagination="activityCount"
              :items="formattedOutgoingActivities"
              :activities="outgoingActivities"
              :direction="'outgoing'"
              :loading="loading"
            />
            <activity-table
              v-else
              :headers="headers"
              :items="formattedOutgoingActivities"
              :pagination="activityCount"
              :direction="'outgoing'"
              :loading="loading"
              single-table
            />
            <load-more
              v-if="loadMoreBtn"
              :activityCount="activityCount"
              :direction="'outgoing'"
              :loadMoreItemsCount="loadMoreItemsCount"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { PhX } from "phosphor-vue";
import deviceChecker from "@/mixins/deviceChecker";
import formattedActivities from "@/mixins/formattedActivities";
export default {
  components: {
    PhX,
    ActivityFilters: () => import("@/components/Activities/ActivityFilters"),
    ActivityDownload: () => import("@/components/Activities/ActivityDownload"),
    DateFilter: () => import("@/components/Activities/DateFilter"),
    ActivityIterator: () => import("@/components/Activities/ActivityIterator"),
    ActivityTable: () => import("@/components/Activities/ActivityTable"),
    LoadMore: () => import("@/components/Activities/LoadMore"),
  },
  mixins: [deviceChecker, formattedActivities],
  data() {
    return {
      breakingPoint: 960,
      headers: [
        {
          text: this.$t("activities.table.receiver"),
          align: "start",
          sortable: false,
          value: "user",
        },
        { text: this.$t("activities.table.date"), value: "date" },
        {
          text: this.$t("activities.table.status"),
          value: "Status",
          cellClass: "status-wrap",
        },
        {
          text: this.$t("activities.table.type"),
          value: "Type",
          cellClass: "type-wrap",
        },
        {
          text: this.$t("activities.table.amount"),
          value: "amount",
          align: "end",
          cellClass: "amount-wrap",
        },
      ],
      loadMoreItemsCount: 10,
    };
  },
  async created() {
    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 30);

    await this.$store.dispatch("wallet/getFilters", "outgoing");

    this.$store.dispatch("wallet/getUserActivity", {
      filter: "",
      direction: "outgoing",
      skip: 0,
      limit: 10,
      dateFrom: dateFrom.toLocaleString("en-US"),
      dateTo: new Date().toLocaleString("en-US"),
    });
  },
  computed: {
    ...mapGetters({
      outgoingFilters: "wallet/outgoingFilters",
      outgoingActivities: "wallet/userOutgoing",
      activityCount: "wallet/activityCount",
      loading: "wallet/activityLoading",
      loadMoreBtn: "wallet/activitiesShowloadMoreBtn",
    }),
    formattedOutgoingActivities() {
      return this.formatActivities(this.outgoingActivities);
    },
  },
  methods: {
    goToWallet() {
      this.$router.push("/wallet");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.content-wrap {
  box-shadow: $box-shadow-light;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content-wrap {
    box-shadow: none;
  }
}
</style>
