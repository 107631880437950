import Vue from "vue";
import Vuex from "vuex";
import Wallet from "./wallet";
import Auth from "./auth";
import Snackbar from "./snackbar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    wallet: Wallet,
    auth: Auth,
    snackbar: Snackbar,
  },
});
