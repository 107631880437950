import Vue from "vue";
import Auth from "@/api/Auth";
import Permissions from "@/api/Permissions";
import Donations from "@/api/Donations";
import ErrorHandling from "@/plugins/errorHandling";

const auth = new Auth();
const permissions = new Permissions();
const donations = new Donations();
const errorHandling = new ErrorHandling();

const setToken = (context, { token, decodedRefreshToken }) => {
  Vue.helpers.setCookie("loginToken", token, 30);
  Vue.helpers.setCookie("refreshToken", decodedRefreshToken, 30);
};

const clearToken = () => {
  Vue.helpers.clearCookie("loginToken");
};

const logout = ({ commit }) => {
  Vue.helpers.clearCookie("loginToken");
  window.location.href = `${process.env.VUE_APP_SSO_LOGOUT}`;
};

const getAuthUser = async ({ commit, dispatch }) => {
  try {
    const { payload } = await auth.getAuthUser();
    commit("setAuthUser", payload);
    dispatch("getAuthUserPermissions");
    dispatch("getDonations");
    dispatch("getDonationsPercentage");
  } catch (error) {
    errorHandling.onError(error);
  }
};

const getAuthUserPermissions = async ({ commit }) => {
  try {
    commit("setPermissionsLoading", true);
    const { data } = await permissions.getAuthUserPermissions();
    commit("setAuthUserPermissions", data);
  } catch (error) {
    errorHandling.onError(error);
  } finally {
    commit("setPermissionsLoading", false);
  }
};

const getDonations = async ({ commit }) => {
  try {
    commit("setDonationsLoading", true);
    const { data } = await donations.getDonations();
    commit("setDonations", data.result);
  } catch (error) {
    errorHandling.onError(error);
  } finally {
    commit("setDonationsLoading", false);
  }
};

const getDonationsPercentage = async ({ commit }) => {
  try {
    commit("setDonationsPercentageLoading", true);
    const { data } = await donations.getDonationsPercentage();
    commit("setDonationsPercentage", data);
  } catch (error) {
    errorHandling.onError(error);
  } finally {
    commit("setDonationsPercentageLoading", false);
  }
};

const getUserLocation = async function ({ commit }) {
  try {
    const { payload } = await auth.getUserLocation()
    commit('setLocation', payload)
  } catch (error) {
    const {
      response: { data },
    } = error
    throw new Error(data)
  }
};

export default {
  setToken,
  clearToken,
  logout,
  getAuthUser,
  getAuthUserPermissions,
  getDonations,
  getDonationsPercentage,
  getUserLocation
};
