import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  show: false,
  text: "",
  color: "",
  timeout: 3000,
  minWidth: "360",
  action: false,
  actionText: "",
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
