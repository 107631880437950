<template>
  <span :class="componentClass" @click="emitClick">
    <router-link :class="linkClass" v-if="link" :to="link">
      <span :id="id" :style="{ color: textColor }">{{ linkText }}</span>
    </router-link>
    <span
      v-else
      :id="id"
      :style="{ cursor: underline ? 'pointer' : '', color: textColor }"
      >{{ text }}
    </span>
  </span>
</template>

<script>
export default {
  name: "BlockText",
  props: {
    text: {
      type: [String, Number],
    },
    align: {
      type: String,
      default: "left",
      required: false,
    },
    weight: {
      type: String,
      default: "regular",
      required: false,
    },
    color: {
      type: String,
      default: "",
      required: false,
    },
    type: {
      type: String,
      default: "body-1",
      required: false,
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: String,
      default: null,
      required: false,
    },
    id: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    linkText() {
      return this.text || this.$t("SeeAll");
    },
    alignClass() {
      switch (this.align) {
        case "center":
          return "text-center ";
        case "right":
          return "text-right ";
        default:
          return "text-left ";
      }
    },
    weightClass() {
      switch (this.weight) {
        case "black":
          return "font-weight-black ";
        case "bold":
          return "font-weight-bold ";
        case "light":
          return "font-weight-light ";
        case "italic":
          return "font-italic ";
        case "thin":
          return "font-weight-thin ";
        default:
          return "font-weight-regular ";
      }
    },
    typeClass() {
      switch (this.type) {
        case "headline":
          return "headline ";
        case "title":
          return "title ";
        case "subtitle-1":
          return "subtitle-1 ";
        case "body-2":
          return "body-2 ";
        case "caption":
          return "caption ";
        default:
          return "body-1 ";
      }
    },
    textColor() {
      switch (this.color) {
        case "white":
          return "#fff";
        case "black":
          return "#000";
        case "primary":
          return this.$vuetify.theme.dark ? "#fff" : "#222";
        case "secondary":
          return "#888";
        case "silver":
          return "#bfbfc0";
        case "trolleyGrey":
          return "#808081";
        default:
          return "";
      }
    },
    underlineClass() {
      return this.underline ? "text-decoration-underline " : "";
    },
    linkClass() {
      return "text-decoration-underline primary--text " + this.alignClass;
    },
    componentClass() {
      return (
        this.alignClass +
        this.weightClass +
        this.typeClass +
        this.underlineClass
      );
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
