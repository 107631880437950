<template>
  <v-footer
    app
    padless
    absolute
    color="appBcg"
    class="pa-10 flex-column align-stretch app-footer"
  >
    <div class="pb-6 d-flex align-center flex-wrap app-footer__upper-row">
      <!-- <div class="d-flex align-center mr-10">
        <block-text
          :text="$t('footer.language.title')"
          color="silver"
          class="mr-7"
          type="body-1"
        />
        <v-select
          v-model="lang"
          :items="langItems"
          dense
          hide-details
          solo
          append-icon="mdi-chevron-down"
          class="rounded-lg"
        ></v-select>
      </div> -->
      <div class="d-flex align-center">
        <div v-for="(item, linkIndex) in links" :key="linkIndex">
          <block-text
            class="cursor-pointer"
            :text="item.label"
            weight="bold"
            @click.native="linkClick(item.link)"
          />
          <v-icon color="grey" class="mx-4" :class="{ 'mr-0': linkIndex === 2 }"
            >mdi-circle-small</v-icon
          >
        </div>
      </div>
      <div class="d-flex align-center">
        <block-text :text="$t('footer.follow')" color="silver" type="body-1" />
        <v-hover v-slot="{ hover }">
          <ph-facebook-logo
            class="cursor-pointer ml-8"
            :size="24"
            weight="bold"
            :color="hover ? '#222' : '#808081'"
            @click="goToFacebook"
          />
        </v-hover>
        <v-hover v-slot="{ hover }">
          <ph-twitter-logo
            class="cursor-pointer ml-8"
            :size="24"
            weight="bold"
            :color="hover ? '#222' : '#808081'"
            @click="goToTwitter"
          />
        </v-hover>
        <v-hover v-slot="{ hover }">
          <ph-instagram-logo
            class="cursor-pointer ml-8"
            :size="24"
            weight="bold"
            :color="hover ? '#222' : '#808081'"
            @click="goToInstagram"
          />
        </v-hover>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-img width="60" height="24" :src="require('@/assets/logo.svg')" />
      </div>
    </div>
    <v-divider />
    <div class="pt-6">
      <block-text :text="rightsText" color="silver" type="caption" />
    </div>
  </v-footer>
</template>

<script>
import { PhFacebookLogo, PhInstagramLogo, PhTwitterLogo } from "phosphor-vue";
export default {
  name: "AppFooter",
  components: {
    PhFacebookLogo,
    PhInstagramLogo,
    PhTwitterLogo,
  },
  data() {
    return {
      lang: localStorage.lang,
      langItems: [
        { text: this.$t("footer.language.english"), value: "en" },
        { text: this.$t("footer.language.italian"), value: "it" },
      ],
      links: [
        {
          label: this.$t("footer.links.contact"),
          link: "mailto:info@tatatu.com",
        },
        {
          label: this.$t("footer.links.privacy"),
          link: "https://www.tatatu.com/en/privacy-policy/",
        },
        {
          label: this.$t("footer.links.tac"),
          link: "https://www.tatatu.com/en/terms-and-conditions/",
        },
      ],
    };
  },
  computed: {
    rightsText() {
      return `${this.$t(
        "footer.rights"
      )} © ${new Date().getFullYear()} TATATU™`;
    },
  },
  methods: {
    linkClick(link) {
      window.open(link, "_blank");
    },
    goToFacebook() {
      window.open("https://www.facebook.com/TatatuOfficial/", "_blank");
    },
    goToTwitter() {
      window.open("https://twitter.com/TaTaTu_Official", "_blank");
    },
    goToInstagram() {
      window.open("https://www.instagram.com/tatatuofficial/", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  &__upper-row {
    gap: 20px;
  }
}

// Language select overrides
.language-select-wrap {
  position: relative;
}

.v-select {
  min-width: 180px;
  width: 180px;
  height: 40px;
  padding: 0;
  margin: 0;
  ::v-deep .v-input__control {
    height: 100%;
    .v-input__slot {
      height: 100%;
      border-radius: $radius-md;
      box-shadow: $box-shadow-medium;
      padding: 0px 10px 0px 16px;
      margin: 0;
      .v-select__slot {
        font-size: 14px;
        font-weight: bold;
      }
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &::v-deep.v-select--is-menu-active {
    .v-input__slot {
      border-bottom-left-radius: $no-radius;
      border-bottom-right-radius: $no-radius;
    }
    & ~ #languageMenu {
      .v-menu__content {
        box-shadow: $select-box-shadow;
        border-top-left-radius: $no-radius;
        border-top-right-radius: $no-radius;
      }
    }
  }

  &.theme--dark {
    ::v-deep .v-input__control {
      .v-input__slot {
        box-shadow: $box-shadow-dark;
      }
    }

    &.v-select--is-menu-active {
      ::v-deep .v-input__slot {
        border-bottom-left-radius: $no-radius;
        border-bottom-right-radius: $no-radius;
      }
    }
  }
}

#languageMenu {
  position: absolute;
  bottom: 0;
  ::v-deep .v-menu__content {
    box-shadow: $box-shadow-medium;
    border-top-left-radius: $no-radius;
    border-top-right-radius: $no-radius;
    border-bottom-left-radius: $radius-md;
    border-bottom-right-radius: $radius-md;
    .v-select-list {
      padding: 0;
    }
    .v-list-item {
      min-height: 0;
      &.v-list-item--active {
        font-weight: bold;

        .v-list-item__title {
          background-color: transparent;
          color: var(--v-primary-base);
        }
      }
    }
    .v-list-item__title {
      font-size: 12px;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .date-select-wrap .date-select {
    height: 30px;
  }
}
</style>
