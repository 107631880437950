<template>
  <v-row class="px-6 py-6">
    <v-col xs="12" sm="12" md="5" lg="4" xl="3">
      <wallet-widget />
      <donations-widget />
      <earning-widget />
    </v-col>
    <v-col xs="12" sm="12" md="7" lg="8" xl="9">
      <div class="activities px-3 px-sm-5 py-sm-3">
        <v-row>
          <v-col
            class="widget-wrapper justify-space-between d-flex flex-column pa-6"
            cols="12"
          >
            <div
              v-if="!isMobileOrTablet"
              class="d-flex align-center justify-space-between pb-4"
            >
              <div class="d-flex align-center">
                <ph-archive-tray :size="30" :color="'#808081'" />
                <block-text
                  :text="$t('activities.myEarnings')"
                  color="primary"
                  type="title"
                  class="ml-3"
                />
              </div>
              <div
                class="d-flex align-center justify-center cursor-pointer"
                @click="goToEarningPage()"
              >
                <block-text
                  :text="$t('activities.seeMore')"
                  color="primary"
                  weight="bold"
                  type="body-2"
                  class="nav-link mr-2"
                  :style="{ lineHeight: 1 }"
                />
                <ph-caret-right
                  :size="20"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                />
              </div>
            </div>
            <div v-if="isMobileOrTablet">
              <div class="d-flex align-center">
                <ph-archive-tray :size="30" :color="'#bfbfc0'" />
                <block-text
                  :text="$t('activities.myEarnings')"
                  color="primary"
                  type="title"
                  class="ml-3"
                />
              </div>
              <activity-iterator
                :pagination="activityCount"
                :items="formattedIncomeActivities"
                :direction="'income'"
                :loading="income30Loading"
                last30
              />
              <div
                class="d-flex align-center justify-center pt-5"
                @click="goToEarningPage()"
              >
                <block-text
                  :text="$t('activities.seeMore')"
                  color="primary"
                  weight="bold"
                  type="body-1"
                  class="nav-link cursor-pointer"
                />
                <ph-caret-right
                  :size="16"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                />
              </div>
            </div>

            <activity-table
              v-else
              :headers="incomeHeaders"
              :pagination="activityCount"
              :items="formattedIncomeActivities"
              :direction="'income'"
              :loading="income30Loading"
              last30
            />

            <div
              v-if="!isMobileOrTablet"
              class="d-flex align-center justify-center pt-5"
              @click="goToEarningPage()"
            >
              <block-text
                :text="$t('activities.seeMore')"
                color="primary"
                weight="bold"
                type="body-1"
                class="nav-link cursor-pointer"
              />
              <ph-caret-right
                :size="16"
                :color="$vuetify.theme.dark ? '#fff' : '#222'"
              />
            </div>
          </v-col>

          <v-col
            class="widget-wrapper justify-space-between d-flex flex-column pa-6"
            cols="12"
          >
            <div class="d-flex align-center justify-space-between pb-4">
              <div class="d-flex align-center">
                <v-img max-width="30" :src="require('@/assets/spending.svg')" />
                <block-text
                  :text="$t('activities.mySpendings')"
                  color="primary"
                  type="title"
                  class="ml-3"
                />
              </div>
              <div
                v-if="!isMobileOrTablet"
                class="d-flex align-center justify-center cursor-pointer"
                @click="goToSpendingPage()"
              >
                <block-text
                  :text="$t('activities.seeMore')"
                  color="primary"
                  weight="bold"
                  type="body-2"
                  class="nav-link mr-2"
                  :style="{ lineHeight: 1 }"
                />
                <ph-caret-right
                  :size="20"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                />
              </div>
            </div>
            <div v-if="isMobileOrTablet" class="content-wrap">
              <activity-iterator
                :pagination="activityCount"
                :items="formattedOutgoingActivities"
                :direction="'outgoing'"
                :loading="outgoing30Loading"
                last30
              />
            </div>

            <activity-table
              v-else
              :headers="outgoingHeaders"
              :pagination="activityCount"
              :items="formattedOutgoingActivities"
              :direction="'outgoing'"
              :loading="outgoing30Loading"
              last30
            />
            <div
              class="d-flex align-center justify-center pt-5"
              @click="goToSpendingPage()"
            >
              <block-text
                :text="$t('activities.seeMore')"
                color="primary"
                weight="bold"
                type="body-1"
                class="nav-link cursor-pointer"
              />
              <ph-caret-right
                :size="16"
                :color="$vuetify.theme.dark ? '#fff' : '#222'"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { PhArchiveTray, PhCaretRight } from "phosphor-vue";
import deviceChecker from "@/mixins/deviceChecker";
import formattedActivities from "@/mixins/formattedActivities";
export default {
  components: {
    PhArchiveTray,
    PhCaretRight,
    WalletWidget: () => import("@/components/WalletWidget"),
    DonationsWidget: () => import("@/components/DonationsWidget"),
    EarningWidget: () => import("@/components/EarningWidget"),
    ActivityFilters: () => import("@/components/Activities/ActivityFilters"),
    ActivityTable: () => import("@/components/Activities/ActivityTable"),
    ActivityIterator: () => import("@/components/Activities/ActivityIterator"),
  },
  mixins: [deviceChecker, formattedActivities],
  data() {
    return {
      breakingPoint: 1360,
      incomeHeaders: [
        {
          text: this.$t("activities.table.sender"),
          align: "start",
          sortable: false,
          value: "user",
        },
        { text: this.$t("activities.table.date"), value: "date" },
        {
          text: this.$t("activities.table.status"),
          value: "status",
          cellClass: "status-wrap",
        },
        {
          text: this.$t("activities.table.type"),
          value: "type",
          cellClass: "type-wrap",
        },
        {
          text: this.$t("activities.table.amount"),
          value: "amount",
          align: "end",
          cellClass: "amount-wrap",
        },
      ],
      outgoingHeaders: [
        {
          text: this.$t("activities.table.receiver"),
          align: "start",
          sortable: false,
          value: "user",
        },
        { text: this.$t("activities.table.date"), value: "date" },
        {
          text: this.$t("activities.table.status"),
          value: "status",
          cellClass: "status-wrap",
        },
        {
          text: this.$t("activities.table.type"),
          value: "type",
          cellClass: "type-wrap",
        },
        {
          text: this.$t("activities.table.amount"),
          value: "amount",
          align: "end",
          cellClass: "amount-wrap",
        },
      ],
    };
  },
  methods: {
    goToEarningPage() {
      this.$router.push("/earning");
    },
    goToSpendingPage() {
      this.$router.push("/spending");
    },
  },
  async created() {
    await this.$store.commit("wallet/setActivityCount", 10);
    await this.$store.dispatch("wallet/getFilters", "income");
    await this.$store.dispatch("wallet/getFilters", "outgoing");
    await this.$store.dispatch("wallet/getUserActivityLast30Days", {
      filter: "",
      direction: "income",
      skip: 0,
      limit: 4,
    });
    await this.$store.dispatch("wallet/getUserActivityLast30Days", {
      filter: "",
      direction: "outgoing",
      skip: 0,
      limit: 4,
    });
  },
  mounted() {
    if (
      this.$route.query.scanCode == 1 ||
      window.localStorage.getItem("scanCode") == 1
    ) {
      this.$store.commit("wallet/showQRModal", true);
      window.localStorage.removeItem("scanCode");
    } else {
      this.$store.commit("wallet/showQRModal", false);
    }
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      incomeActivities: "wallet/userIncomeLast30Days",
      outgoingActivities: "wallet/userOutgoingLast30Days",
      income30Loading: "wallet/income30Loading",
      outgoing30Loading: "wallet/outgoing30Loading",
      incomeFilters: "wallet/incomeFilters",
      outgoingFilters: "wallet/outgoingFilters",
      activityCount: "wallet/activityCount",
    }),
    formattedIncomeActivities() {
      return this.formatActivities(this.incomeActivities);
    },
    formattedOutgoingActivities() {
      return this.formatActivities(this.outgoingActivities);
    },
  },
};
</script>
