export default {
  data() {
    return {
      isMobileOrTablet: false,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobileOrTablet = window.innerWidth < this.breakingPoint;
    },
  },
};
