const authUser = (state) => state.authUser;
const loading = (state) => state.loading;
const authUserPermissions = (state) => state.authUserPermissions;
const permissionsLoading = (state) => state.permissionsLoading;
const donations = (state) => state.donations;
const donationsLoading = (state) => state.donationsLoading;
const donationsPercentage = (state) => state.donationsPercentage;
const donationsPercentageLoading = (state) => state.donationsPercentageLoading;
const location = (state) => state.location;

export default {
  authUser,
  loading,
  authUserPermissions,
  permissionsLoading,
  donations,
  donationsLoading,
  donationsPercentage,
  donationsPercentageLoading,
  location,
};
