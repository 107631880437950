import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import VueI18n from "vue-i18n";
import en from "@/plugins/i18n/en";
import it from "@/plugins/i18n/it";
import vuetify from "@/plugins/vuetify";
import initAxiosConfig from "@/plugins/axiosConfig";
import Helpers from "@/plugins/HelpersPlugin.js";
import Vuelidate from "vuelidate";
import BlockText from "@/components/Core/BlockText";

Vue.config.productionTip = false;

Vue.component("block-text", BlockText);
Vue.use(new Helpers());
Vue.use(Vuelidate);
Vue.use(VueI18n);

Vue.prototype.$eventHub = new Vue();

const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "en",
  messages: { en, it },
  fallbackLocale: "en",
});

initAxiosConfig();

const app = {
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
};

new Vue(app).$mount("#app");
