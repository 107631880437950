const setAuthUser = (state, payload) => {
  state.authUser = payload;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPermissionsLoading = (state, value) => {
  state.permissionsLoading = value;
};

const setAuthUserPermissions = (state, value) => {
  state.authUserPermissions = value;
};

const setDonations = (state, value) => {
  state.donations = value;
};

const setDonationsLoading = (state, value) => {
  state.donationsLoading = value;
};

const setDonationsPercentage = (state, value) => {
  state.donationsPercentage = value;
};

const setDonationsPercentageLoading = (state, value) => {
  state.donationsPercentageLoading = value;
};

const setLocation = (state, value) => (state.location = value);

export default {
  setAuthUser,
  setLoading,
  setAuthUserPermissions,
  setPermissionsLoading,
  setDonations,
  setDonationsLoading,
  setDonationsPercentage,
  setDonationsPercentageLoading,
  setLocation,
};
