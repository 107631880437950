import Api from "./Api";
import axios from "axios";

export default class Auth extends Api {
  constructor() {
    super();
    this.baseEndpointUrl = process.env.VUE_APP_API;
  }

  async getAuthUser() {
    const url = `${this.baseEndpointUrl}provisionnew/v2/user/info`;
    const headers = this.setHeaders();
    const { data } = await axios.get(url, { headers });
    return data;
  }

  async getUserLocation() {
    const url = `${this.baseEndpointUrl}provisionnew/v2/user/country-code`;
    const headers = this.setHeaders()
    const { data } = await axios.get(url, {
      headers,
    })
    return data
  }
}
