<template>
  <div></div>
</template>
<script>
export default {
  name: "Health",
  components: {},
  data() {
    return {};
  },
  async created() {}
};
</script>

<style></style>
