export default {
  header: {
    myWallet: "My Wallet",
    wallet: "Wallet",
    walletAmount: "Wallet Amount",
    scanQrCode: "Scan QR Code",
  },
  wallet: {
    accountBalance: "Account Balance",
    pendingTransfer: "Pending Outgoing Transfers",
    myAddress: "My Address",
    copy: "Copy",
    sendTTU: "Send TTU",
    redeemNow: "Redeem Now",
    copyingSuccessful: "Copying successful!",
    copyingUnuccessful: "Copying not successful!",
  },
  donations: {
    title: "My Donations",
    totalDonated: "TOTAL Donated",
    totalReceived: "TOTAL Received",
  },
  earning: {
    title: "Earning rules",
    noDonationText:
      "Your earnings can be affected by the percentage you are giving back to the community.",
    descriptionPart1: "Amazing! You are donating",
    descriptionPart2: "of all your earnings.",
    cta1: "Earning rules",
    cta2: "Donate",
    aboutWalletText1:
      "Here you can see the number of TTU Coins you have earned by inviting your friends, getting new followers, likes, views, comments or shares of your posts.",
    aboutWalletText2:
      "You can redeem TTU Coins by clicking on 'Redeem Now'. You will have direct access to our online store to choose and buy the products that are right for you. We remind you that for the moment you can use your TTU Coins only and exclusively on the website ecommerce.tatatu.com.",
    aboutWalletText3:
      "Below is the TTU balance that you have earned as rewards. You can also use your wallet to send TTU Coins to your family and friends who can spend it on our online store.",
    rulesArray: {
      forEveryPostYouView: "For every post you view for the first time",
      forEachLikeOnYourPost: "For each like on your post",
      forEachLikeOnYourComment: "For each like on your comment",
      forEveryShareOfYourPost: "For every share of your post",
      everyTimeSomeoneTagsYou: "Every time someone tags you",
      forEachCommentOnYourPost: "For each comment on your post",
      forEveryNewFollower: "For every new follower",
      inviteFriends:
        "Invite friend. As soon as new user accepts invitation and registers",
      invitedByFriend:
        "Invited by friend. If you have been invited you will be rewarded with additional coins",
      watchContent:
        "Watch content. For every 100 min of watching, reward interval is set to 30 sec",
      whenYouRegister: "You will earn when you register",
    },
  },
  activities: {
    myEarnings: "My Earnings",
    mySpendings: "My Spendings",
    downloadActivity: "Download Activity",
    seeMore: "See More",
    showLess: "Show Less",
    transactionDetails: "Transaction details",
    filters: {
      allActivities: "All Activity",
      openingBalance: "Opening Balance",
      earnedWatching: "Earned Watching",
      earnedFromFriends: "Earned From Friends",
      earnedFromSocialActions: "Earned From Social Actions",
      ecommercePayment: "ECommerce Payment",
      auctionPayment: "Auction Payment",
      partnerActions: "Earned From Partner Actions",
      paymentsReceived: "Payments Received",
      ERC20Transfer: "ERC20 Transfer",
      paymentsSent: "Payments Sent",
      redeemed: "Redeemed",
      pendingIncoming: "Pending Incoming",
      pendingOutgoing: "Pending Outgoing",
      date30: "Past 30 days",
      date60: "Past 60 days",
      date90: "Past 90 days",
      date90Plus: "90+ days",
    },
    table: {
      user: "User",
      sender: "Sender",
      receiver: "Receiver",
      date: "Date",
      status: "Status",
      type: "Type",
      amount: "Amount",
      masterWallet: "Enterprise Ltd",
      myCommunity: "My Community",
      accept: "Accept",
      reject: "Reject",
      loadMore: "Load More",
      noDataTitle: "There are no transactions in the last {dateFilterVal} days",
      noDataDescription:
        "Your transaction list will appear Here but currently there are no activities. Please create some transaction or simply start earning TTU coins with Your activities on the platform.",
    },
  },
  dialog: {
    print: "Print",
    sendTTU: "Send TTU",
    accountNumber: "Account number",
    accept: "Accept",
    reject: "Reject",
    confirm: "Confirm",
    confirmDebit: "Confirm Debit",
    ttuDebit: "TATATU's Coin Debit",
    startScanning: "Start Scanning",
    scanQRcodeFromUser:
      "Please scan a QR code from a TATATU's User and then continue with debit process",
    totalInTTU: "TOTAL amount in TATATU's Coins",
    enterAmountToDebit: "Enter the amount to debit",
    billPaymentSentTo: "Bill payment sent to",
    recognizedUser: "Recognized TATATU's User",
    close: "Close",
    gotIt: "Ok, Got it",
    defaultError:
      "We are very sorry but something went wrong, we are not able to complete this transfer. Please, retry the process or try again later, thanks.",
    transactionCompleted: "Transaction completed",
    transactionPending: "Transaction pending",
    transactionExpired: "Transaction expired",
    transactionRejected: "Transaction rejected",
    pending: "Pending...",
    transactionNumber: "Transaction Number",
    amount: "Amount",
    enterAmount: "Enter Amount to Send",
    personalMessage: "Personal message",
    enterMessage: "Enter Your personal message",
    errorTitle: "Transaction rejected!",
    walletError1: "Field is required",
    walletError2: "Invalid Wallet Address",
    sendTTUText1: "You sent {amount} TTU to {user}",
    sendTTUText2:
      "The recipient has 24 hours to accept the payment. You can see the status of the transaction in the activity section.",
    rejectText1:
      "Please confirm that you want to reject {amount} TTU from {user}",
    rejectText2:
      "Doing so means that you will not receive {amount} TTU. {user} will need to resend them to you again if you want to receive them.",
    acceptText1:
      "Please click Confirm below to receive {amount} TTU from {user}",
    acceptText2:
      "Doing so means that {amount} TTU will move from {user}'s account into yours. This action cannot be undone, but you can send {amount} TTU back to {user} later if needed.",
    dialogFooterText1: "The recipient has 24 hours to accept the payment.",
    dialogFooterText2:
      "You can see the status of the transaction in the activity section.",
    recipientWallet: "Recipient TATATU Wallet Address",
    sendTTUsubtitle:
      "If You want to send TATATU Coins to somebody, please just enter below the amount of coins You want to send and the recipient's wallet address.",
    createTransactionError:
      "There was an error with the transaction, please try again later",
    createTransactionSuccess: "Successful transaction!",
    tokens: "tokens",
    notEnoughTTU: "Not enough TTU tokens",
  },
  footer: {
    language: {
      title: "Language",
      english: "English",
      italian: "Italian",
    },
    links: {
      privacy: "Privacy policy",
      contact: "Contact",
      tac: "Terms & Conditions",
    },
    follow: "Follow Us",
    rights: "All Rights Reserved by",
  },
  discard: "Discard",
  cancel: "Cancel",
};
