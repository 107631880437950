<template>
  <div class="fill-height d-flex align-center justify-center">
    <loader v-if="loading" />
  </div>
</template>

<script>
import Loader from "@/components/Core/Loader";
export default {
  name: "Auth",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.$store.dispatch("auth/clearToken");
    const { token, refreshToken } = this.$route.query;
    const decodedRefreshToken = decodeURIComponent(refreshToken);

    if (token) {
      await this.$store.dispatch("auth/setToken", {
        token,
        decodedRefreshToken,
      });
    } else {
      window.location.href = `${process.env.VUE_APP_SSO}?callback=${process.env.VUE_APP_DOMAIN}auth`;
    }

    await this.$store.dispatch("wallet/getUser");
    this.$router.push({
      name: "wallet",
    });
  },
};
</script>

<style></style>
