const user = (state) => state.user;
const userIncome = (state) => state.userIncome;
const userOutgoing = (state) => state.userOutgoing;
const userIncomeLast30Days = (state) => state.userIncomeLast30Days;
const userOutgoingLast30Days = (state) => state.userOutgoingLast30Days;
const incomeFilters = (state) => state.incomeFilters;
const outgoingFilters = (state) => state.outgoingFilters;
const currentDateFilter = (state) => state.currentDateFilter;
const loading = (state) => state.loading;
const activityLoading = (state) => state.activityLoading;
const income30Loading = (state) => state.income30Loading;
const outgoing30Loading = (state) => state.outgoing30Loading;
const activityCount = (state) => state.activityCount;
const activitiesShowloadMoreBtn = (state) => state.activitiesShowloadMoreBtn;
const activeFilter = (state) => state.activeFilter;
const walletOwner = (state) => state.walletOwner;
const error = (state) => state.error;
const sendTTULoading = (state) => state.sendTTULoading;
const acceptTTULoading = (state) => state.acceptTTULoading;
const rejectTTULoading = (state) => state.rejectTTULoading;
const transferReceiver = (state) => state.transferReceiver;
const exchangeRates = (state) => state.exchangeRates;
const exchangeRatesError = (state) => state.error.exchangeRates;
const exchangeRatesLoading = (state) => state.loading.exchangeRates;
const showQRModal = (state) => state.showQRModal;

export default {
  user,
  userIncome,
  userOutgoing,
  userIncomeLast30Days,
  userOutgoingLast30Days,
  incomeFilters,
  outgoingFilters,
  currentDateFilter,
  loading,
  activityLoading,
  income30Loading,
  outgoing30Loading,
  activityCount,
  activitiesShowloadMoreBtn,
  activeFilter,
  walletOwner,
  error,
  sendTTULoading,
  acceptTTULoading,
  rejectTTULoading,
  transferReceiver,
  exchangeRates,
  exchangeRatesError,
  exchangeRatesLoading,
  showQRModal,
};
