<template>
  <div class="print-wrapper d-flex flex-column py-10 px-5 px-md-0">
    <block-text
      :text="$t('activities.transactionDetails')"
      color="primary"
      weight="bold"
      type="headline"
    />
    <block-text
      :text="transactionType"
      class="mt-8"
      color="primary"
      type="body-1"
    />
    <block-text
      v-if="transactionDate"
      :text="transactionDate | convertToLocalFullDateTime"
      class="mt-4"
      color="primary"
      type="body-1"
    />
    <div v-if="transactionStatus" class="d-flex align-center mt-4">
      <block-text
        :text="'Status: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <block-text
        :text="transactionStatus"
        class="ml-2"
        color="primary"
        type="body-1"
      />
    </div>
    <div v-if="transactionNumber" class="d-flex align-center mt-4">
      <block-text
        :text="'Transaction Number: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <block-text
        :text="transactionNumber"
        class="ml-2"
        color="primary"
        type="body-1"
      />
    </div>
    <div class="d-flex align-center mt-4">
      <block-text
        :text="'Amount: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <div class="d-flex align-center">
        <img min-width="40" :src="tokenPath" class="ml-2" />
        <block-text
          :text="transactionAmount"
          class="ml-2"
          color="primary"
          type="body-1"
        />
      </div>
    </div>
    <v-divider class="mt-5"></v-divider>
    <div class="d-flex align-center mt-4">
      <block-text
        :text="'Account Number: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <block-text
        :text="transactionAccountNumber"
        class="ml-2"
        color="primary"
        type="body-1"
      />
    </div>
    <div class="d-flex align-center mt-4">
      <block-text
        :text="'User name: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <block-text
        :text="transactionUser"
        class="ml-2"
        color="primary"
        type="body-1"
      />
    </div>
    <div class="d-flex align-center mt-4">
      <block-text
        :text="'Personal note: '"
        color="secondary"
        type="body-1"
        weight="bold"
      />
      <block-text
        :text="transactionRequestNote"
        class="ml-2"
        color="primary"
        type="body-1"
      />
    </div>
    <v-divider class="mt-5"></v-divider>
    <div class="d-flex flex-column align-center mt-16">
      <img min-width="240" :src="logoPath" class="logo-img" />
      <block-text
        :text="'© 2023 TATATU S.P.A.'"
        class="mt-5 copyright-txt"
        color="primary"
        type="body-1"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import dateConvertor from "@/mixins/dateConvertor";
export default {
  mixins: [dateConvertor],
  data() {
    return {
      transactionType: "",
      transactionDate: "",
      transactionStatus: "",
      transactionNumber: "",
      transactionAmount: "",
      transactionAccountNumber: "",
      transactionUser: "",
      transactionRequestNote: "",
    };
  },
  mounted() {
    if (!localStorage.transactionUser) {
      this.$router.push({
        name: "wallet",
      });
    } else {
      this.transactionUser =
        localStorage.transactionUser == "null"
          ? ""
          : localStorage.transactionUser;
    }
    if (localStorage.transactionType) {
      this.transactionType =
        localStorage.transactionType == "null"
          ? ""
          : localStorage.transactionType;
    }
    if (localStorage.transactionDate) {
      this.transactionDate =
        localStorage.transactionDate == "null"
          ? ""
          : localStorage.transactionDate;
    }
    if (localStorage.transactionStatus) {
      this.transactionStatus =
        localStorage.transactionStatus == "null"
          ? ""
          : localStorage.transactionStatus;
    }
    if (localStorage.transactionNumber) {
      this.transactionNumber =
        localStorage.transactionNumber == "null"
          ? ""
          : localStorage.transactionNumber;
    }
    if (localStorage.transactionAmount) {
      this.transactionAmount =
        localStorage.transactionAmount == "null"
          ? ""
          : localStorage.transactionAmount;
    }
    if (localStorage.transactionAccountNumber) {
      this.transactionAccountNumber =
        localStorage.transactionAccountNumber == "null"
          ? ""
          : localStorage.transactionAccountNumber;
    }
    if (localStorage.transactionRequestNote) {
      this.transactionRequestNote =
        localStorage.transactionRequestNote == "null"
          ? ""
          : localStorage.transactionRequestNote;
    }

    this.printPage();
  },
  computed: {
    tokenPath() {
      return `${process.env.VUE_APP_DOMAIN}/images/ttuToken.png`;
    },
    logoPath() {
      return `${process.env.VUE_APP_DOMAIN}/images/logo.png`;
    },
  },
  methods: {
    printPage: _.debounce(function () {
      window.print();
    }, 500),
  },
  async beforeDestroy() {
    localStorage.removeItem("transactionUser");
    localStorage.removeItem("transactionType");
    localStorage.removeItem("transactionDate");
    localStorage.removeItem("transactionStatus");
    localStorage.removeItem("transactionNumber");
    localStorage.removeItem("transactionAmount");
    localStorage.removeItem("transactionAccountNumber");
    localStorage.removeItem("transactionRequestNote");
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.print-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .logo-img {
    width: 160px;
  }
  .copyright-txt {
    font-size: 12px !important;
  }
}
</style>
