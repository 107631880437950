import Api from "./Api";
import axios from "axios";

export default class Donations extends Api {
  constructor() {
    super();
    this.baseEndpointUrl =
      process.env.VUE_APP_API + "provisionnew/v2/donations";
    this.userDonationsUrl =
      process.env.VUE_APP_API + "triboonew/api/v1.0/wallet/donations-sum";
  }

  async getDonations() {
    const url = this.userDonationsUrl;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getDonationsPercentage() {
    const url = this.baseEndpointUrl;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }
}
