<template>
  <v-app :dark="$vuetify.theme.dark">
    <v-dialog
      v-model="dialog"
      width="600"
      @keydown.esc="hideDialog"
      tabindex="0"
    >
      <v-card class="pa-7 pa-md-14 rounded-lg">
        <dialog-header
          :headerIcon="dialogHeaderIcon"
          :title="dialogTitle"
          :passed-data="passedData"
        />
        <component
          v-if="dialog"
          :passed-data="passedData"
          :is="dialogComponent"
        />
      </v-card>
    </v-dialog>
    <t-snackbar />
    <div v-if="!loading || !!$route.meta.public">
      <app-bar v-if="!$route.meta.hideNav" />
      <v-main>
        <router-view />
      </v-main>
      <app-footer v-if="!$route.meta.hideNav" />
    </div>
    <div class="d-flex align-center justify-center viewport-full-height" v-else>
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import AppBar from "@/components/Layout/AppBar.vue";
import AppFooter from "@/components/Layout/AppFooter.vue";
export default {
  name: "App",
  components: {
    AppBar,
    AppFooter,
    TSnackbar: () => import("@/components/Core/TSnackbar"),
    DialogHeader: () => import("@/components/Core/DialogHeader"),
  },
  data() {
    return {
      dialog: false,
      dialogComponent: "",
      passedData: "",
      dialogTitle: null,
      dialogHeaderIcon: null,
      dialogIcon: null,
      dialogPath: null,
      loading: true,
    };
  },
  mounted() {
    this.$eventHub.$on("toggleDialog", (e) => {
      if (e && e.isOpen) {
        this.passedData = e.payload;
        this.dialogHeaderIcon = e.headerIcon;
        this.dialogIcon = e.dialogIcon;
        this.dialogTitle = e.title;
        this.dialogPath = e.dialogPath;
        this.getDialogComponent(e.dialogPath);
        this.showDialog();
      } else {
        this.hideDialog();
      }
    });
  },
  async created() {
    if (!this.$route.meta.public) {
      await this.getUser();
    }

    const { theme, lang } = this.$route.query;

    if (theme) {
      localStorage.theme = theme;
      theme.toLowerCase() === "dark"
        ? (this.$vuetify.theme.dark = true)
        : false;
    } else {
      this.$vuetify.theme.dark =
        localStorage.theme && localStorage.theme.toLowerCase() === "dark"
          ? true
          : false;
      this.$vuetify.theme.dark = false;
    }

    if (lang) {
      localStorage.lang = lang;
    } else {
      localStorage.lang = "en";
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getUser: "wallet/getUser",
    }),
    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      this.dialog = false;
    },
    getDialogComponent(path) {
      this.dialogComponent = () => import(`@/${path}`);
    },
  },
  destroyed() {
    this.$eventHub.$off("toggleDialog");
  },
};
</script>

<style lang="scss">
#app {
  * {
    letter-spacing: normal !important;
  }
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .cursor-pointer {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #222 !important;
  }

  .widget-wrapper {
    box-shadow: $box-shadow-light;
    border-radius: $radius-md;
    padding: 20px;
    margin-bottom: 30px;
  }

  .bold {
    font-weight: 700 !important;
  }

  .viewport-full-height {
    height: 100vh;
  }

  .button-loader circle {
    stroke-width: 3px;
  }

  .v-card--link:before {
    background: transparent !important;
  }

  .theme--dark {
    .widget-wrapper {
      box-shadow: $box-shadow-dark;
      &.wallet-widget-wrap {
        background: #222 !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #1e1e1e inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }

  .truncate-text span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .v-dialog {
    border-radius: 20px !important;
  }

  .nav-link .text-decoration-underline {
    text-decoration: none !important;
  }

  .v-main {
    background-color: var(--v-appBcg-base);
  }

  .v-btn:before {
    background-color: transparent !important;
  }

  &.theme--dark {
    .v-application--wrap {
      background: #222 !important;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-main {
      padding-bottom: 300px !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-main {
      padding-bottom: 350px !important;
    }
    .headline {
      font-size: 18px !important;
    }
    .title {
      font-size: 16px !important;
    }
    .widget-wrapper {
      margin-bottom: 20px;
    }
  }
}
</style>
