const setUser = (state, value) => {
  state.user = value;
};

const setUserIncomeLast30Days = (state, value) => {
  state.userIncomeLast30Days = value;
};

const setUserOutgoingLast30Days = (state, value) => {
  state.userOutgoingLast30Days = value;
};

const setUserIncome = (state, value) => {
  state.userIncome = value;
};

const setUserOutgoing = (state, value) => {
  state.userOutgoing = value;
};

const setIncomeFilters = (state, value) => {
  state.incomeFilters = value;
};

const setOutgoingFilters = (state, value) => {
  state.outgoingFilters = value;
};

const setWalletOwner = (state, value) => {
  state.walletOwner = value;
};

const setTTUToWallet = (state, value) => {
  state.TTUToWallet = value;
};

const setCurrentDateFilter = (state, value) => {
  state.currentDateFilter = value;
};

const setAcceptTTU = (state, value) => {
  state.acceptTTU = value;
};

const setRejectTTU = (state, value) => {
  state.rejectTTU = value;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setError = (state, value) => {
  state.error = value;
};

const setActivityLoading = (state, value) => {
  state.activityLoading = value;
};

const setIncome30Loading = (state, value) => {
  state.income30Loading = value;
};

const setOutgoing30Loading = (state, value) => {
  state.outgoing30Loading = value;
};

const setActivityCount = (state, payload) => {
  state.activityCount = payload;
};

const setActivitiesShowloadMoreBtn = (state, value) => {
  state.activitiesShowloadMoreBtn = value;
};

const setActiveFilter = (state, value) => {
  state.activeFilter = value;
};

const sendTTULoading = (state, value) => {
  state.sendTTULoading = value;
};

const acceptTTULoading = (state, value) => {
  state.acceptTTULoading = value;
};

const rejectTTULoading = (state, value) => {
  state.rejectTTULoading = value;
};

const setTransferReceiver = (state, value) => {
  state.transferReceiver = value;
};

const setTransferReceiverUsername = (state, value) => {
  if (state.transferReceiver) {
    state.transferReceiver.user.username = value;
  }
};

const setTransferReceiverDebitAmount = (state, value) => {
  if (state.transferReceiver) {
    state.transferReceiver.debitAmount = value;
  }
};

const setExchangeRates = (state, payload) => {
  state.exchangeRates = payload;
};

const setExchangeRatesError = (state, value) => {
  state.exchangeRatesError = value;
};

const setExchangeRatesLoading = (state, value) => {
  state.exchangeRatesLoading = value;
};

const showQRModal = (state, value) => {
  state.showQRModal = value;
};

export default {
  setUser,
  setUserIncome,
  setUserOutgoing,
  setUserIncomeLast30Days,
  setUserOutgoingLast30Days,
  setIncomeFilters,
  setOutgoingFilters,
  setWalletOwner,
  setTTUToWallet,
  setCurrentDateFilter,
  setAcceptTTU,
  setRejectTTU,
  setError,
  setLoading,
  setActivityLoading,
  setIncome30Loading,
  setOutgoing30Loading,
  setActivityCount,
  setActivitiesShowloadMoreBtn,
  setActiveFilter,
  sendTTULoading,
  acceptTTULoading,
  rejectTTULoading,
  setTransferReceiver,
  setTransferReceiverUsername,
  setTransferReceiverDebitAmount,
  setExchangeRates,
  setExchangeRatesError,
  setExchangeRatesLoading,
  showQRModal,
};
