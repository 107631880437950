export default {
  header: {
    myWallet: "Il mio portafoglio",
    scanQrCode: "Scansione codice QR",
    wallet: "Portafoglio",
    walletAmount: "Saldo del Portafoglio",
  },
  wallet: {
    accountBalance: "Saldo dell'account",
    pendingTransfer: "In attesa di trasferimento",
    myAddress: "Il mio indirizzo",
    copy: "Copia",
    sendTTU: "Invia TTU",
    redeemNow: "Spendi i tuoi TTU",
    copyingSuccessful: "Copia riuscita!",
    copyingUnuccessful: "Copia non riuscita!",
  },
  donations: {
    title: "Le mie donazioni",
    totalDonated: "TOTALE Donato",
    totalReceived: "TOTALE ricevuto",
  },
  earning: {
    title: "Regole di guadagno",
    noDonationText:
      "I tuoi guadagni possono essere influenzati dalla percentuale che stai restituendo alla comunità.",
    descriptionPart1: "Sorprendente! Stai donando il",
    descriptionPart2: "di tutti i tuoi guadagni.",
    cta1: "Regole di guadagno",
    cta2: "Donare",
    aboutWalletText1:
      "Qui puoi vedere il numero di monete TTU che hai guadagnato invitando i tuoi amici, ottenendo nuovi follower, Mi piace, visualizzazioni, commenti o condivisioni dei tuoi post.",
    aboutWalletText2:
      'Puoi riscattare le monete TTU facendo clic su "Riscatta ora". Avrai accesso diretto al nostro negozio online per scegliere e acquistare i prodotti che fanno al caso tuo. Ti ricordiamo che per il momento puoi utilizzare i tuoi TTU Coins solo ed esclusivamente sul sito ecommerce.tatatu.com.',
    aboutWalletText3:
      "Di seguito è riportato il saldo TTU che hai guadagnato come ricompensa. Puoi anche utilizzare il tuo portafoglio per inviare monete TTU a familiari e amici che possono spenderle nel nostro negozio online.",
    rulesArray: {
      forEveryPostYouView: "Per ogni post che visualizzi per la prima volta",
      forEachLikeOnYourPost: "Per ogni like al tuo post",
      forEachLikeOnYourComment: "Per ogni mi piace sul tuo commento",
      forEveryShareOfYourPost: "Per ogni condivisione del tuo post",
      everyTimeSomeoneTagsYou: "Ogni volta che qualcuno ti tagga",
      forEachCommentOnYourPost: "Per ogni commento sul tuo post",
      forEveryNewFollower: "Per ogni nuovo follower",
      inviteFriends:
        "Invita un amico. Non appena il nuovo utente accetta l'invito e si registra",
      invitedByFriend:
        "Invitato da un amico. Se sei stato invitato sarai ricompensato con monete aggiuntive",
      watchContent:
        "Guarda i contenuti. Per ogni 100 minuti di visione, l'intervallo di ricompensa è impostato su 30 sec",
      whenYouRegister: "Guadagnerai registrandoti",
    },
  },
  activities: {
    myEarnings: "I miei guadagni",
    mySpendings: "Le mie spese",
    downloadActivity: "Attività download",
    seeMore: "Vedi di più",
    showLess: "Vedi di meno",
    transactionDetails: "Dettagli di Transazione",
    filters: {
      allActivities: "Tutte le attività",
      openingBalance: "Saldo di apertura",
      earnedWatching: "Guadagnati guardando",
      earnedFromFriends: "Guadagnati da amici",
      earnedFromSocialActions: "Guadagnato dalle azioni sociali",
      ecommercePayment: "Pagamento e-commerce",
      auctionPayment: "Pagamento all'asta",
      partnerActions: "Guadagnato dalle azioni dei partner",
      paymentsReceived: "Pagamenti ricevuti",
      ERC20Transfer: "Trasferimento ERC20",
      paymentsSent: "Pagamenti inviati",
      redeemed: "Riscattati",
      pendingIncoming: "In attesa di arrivo",
      pendingOutgoing: "In attesa di uscita",
      date30: "Ultimi 30 giorni",
      date60: "Ultimi 60 giorni",
      date90: "Ultimi 90 giorni",
      date90Plus: "90+ giorni",
    },
    table: {
      user: "Utente",
      sender: "Mittente",
      receiver: "Ricevitore",
      date: "Data",
      status: "Stato",
      type: "Tipo",
      amount: "Importo",
      masterWallet: "Enterprise Ltd",
      myCommunity: "La mia comunità",
      accept: "Accetta",
      reject: "Reject",
      loadMore: "Carica Altro",
      noDataTitle:
        "Non ci sono transazioni negli ultimi {dateFilterVal} giorni",
      noDataDescription:
        "La tua lista delle transazioni apparirà qui ma al momento non ci sono attività. Crea una transazione o inizia semplicemente a guadagnare monete TTU con le tue attività sulla piattaforma.",
    },
  },
  dialog: {
    print: "Stampare",
    sendTTU: "Invia TTU",
    accountNumber: "Numero di conto",
    accept: "Accettare",
    reject: "Rifiutare",
    confirm: "Confermare",
    confirmDebit: "Confermare Debit",
    ttuDebit: "Addebito di monete di TATATU",
    startScanning: "Inizia la scansione",
    scanQRcodeFromUser:
      "Esegui la scansione di un codice QR da un utente di TATATU e poi continua con il processo di addebito",
    totalInTTU: "Importo TOTALE in monete di TATATU",
    enterAmountToDebit: "Inserisci l'importo da addebitare",
    billPaymentSentTo: "Pagamento fattura inviata a",
    recognizedUser: "Utente TATATU's riconosciuto",
    close: "Chiudi",
    gotIt: "Ok capito",
    defaultError:
      "Siamo molto spiacenti ma qualcosa è andato storto, non siamo in grado di completare questo trasferimento. Per favore, riprova il processo o riprova più tardi, grazie.",
    transactionCompleted: "Transazione completata",
    transactionPending: "Transazione in sospeso",
    transactionExpired: "Transazione scaduta",
    transactionRejected: "Transazione rifiutata",
    pending: "In attesa...",
    transactionNumber: "Numero di transazione",
    amount: "Importo",
    enterAmount: "Inserisci l'importo da inviare",
    personalMessage: "Messaggio personale",
    enterMessage: "Inserisci il tuo messaggio personale",
    errorTitle: "Transazione rifiutata!",
    walletError1: "Field is required",
    walletError2: "Invalid Wallet Address",
    sendTTUText1: "Hai inviato {amount} TTU a {user}",
    sendTTUText2:
      "Il destinatario ha 24 ore per accettare il pagamento. Puoi vedere lo stato della transazione nella sezione attività.",
    rejectText1: "Conferma di voler rifiutare {amount} TTU da {user}",
    rejectText2:
      "In questo modo non riceverai {amount} TTU. {user} dovrà inviarteli nuovamente se desideri riceverli.",
    acceptText1:
      "Fai clic su Conferma di seguito per ricevere {amount} TTU da {user}",
    acceptText2:
      "Ciò significa che {amount} TTU si sposterà dall'account di {user} al tuo. Questa azione non può essere annullata, ma puoi inviare {amount} TTU a {utente} in un secondo momento, se necessario.",
    dialogFooterText1: "Il destinatario ha 24 ore per accettare il pagamento.",
    dialogFooterText2:
      "Puoi vedere lo stato della transazione nella sezione delle attività.",
    recipientWallet:
      "Recipient TATATU Wallet AddressIndirizzo del portafoglio TATATU del destinatario",
    sendTTUsubtitle:
      "Se desideri inviare monete TATATU a qualcuno, inserisci sotto la quantità di monete che desideri inviare e l'indirizzo del portafoglio del destinatario.",
    createTransactionError:
      "Si è verificato un errore con la transazione, riprova più tardi",
    createTransactionSuccess: "Transazione riuscita!",
    tokens: "tokens",
    notEnoughTTU: "Non hai abbastanza TTU tokens",
  },
  footer: {
    language: {
      title: "Lingua",
      english: "English",
      italian: "Italiano",
    },
    links: {
      privacy: "l’informativa privacy",
      contact: "Contattaci",
      tac: "Termini & Condizioni",
    },
    follow: "Seguici",
    rights: "Tutti i diritti riservati da",
  },
  discard: "Scartare",
  cancel: "Annulla",
};
