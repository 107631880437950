import Vue from "vue";

export default class Api {
  getToken() {
    return Vue.helpers.getCookie("loginToken");
  }

  getRefreshToken() {
    return Vue.helpers.getCookie("refreshToken");
  }

  setHeaders() {
    const token = this.getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return headers;
  }
}
