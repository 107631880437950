import Vue from "vue";
export default function token({ next }) {
  if (Vue.helpers.getCookie("loginToken")) {
    return next();
  } else {
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    if (searchParams.get("scanCode") == 1) {
      window.localStorage.setItem("scanCode", "1");
    }
    return (window.location.href = `${process.env.VUE_APP_SSO}?callback=${process.env.VUE_APP_DOMAIN}auth`);
  }
}
