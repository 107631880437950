import Api from "./Api";
import axios from "axios";
import { uuid } from "vue-uuid";

export default class Wallet extends Api {
  constructor() {
    super();
    this.baseEndpointUrl = process.env.VUE_APP_API;
  }

  async getUserActivity({ filter, direction, skip, limit, dateFrom, dateTo }) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/user-activity?filter=${filter}&direction=${direction}&skip=${skip}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getUserActivityLast30Days({ filter, direction, skip, limit }) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/user-activity/last-thirty-days?filter=${filter}&direction=${direction}&skip=${skip}&limit=${limit}`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getFilters(filterType) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/filter-groups?filterType=${filterType}`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getWalletById(walletId) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/${walletId}/check-validity`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getWallet() {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/user`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getWalletForPartner(userId) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/partner/user/${userId}/wallet`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async getWalletOwner(walletId) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/${walletId}/owner-details`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }

  async sendTTUToWallet({ walletToID, requestNotes, amount }) {
    const signature = uuid.v4();
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/send-amount-to-wallet`;
    const headers = this.setHeaders();
    const response = await axios.post(
      url,
      {
        walletToID,
        requestNotes,
        amount,
        signature,
      },
      { headers }
    );
    return response;
  }

  async acceptTTU({ transferID, responseNotes }) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/accept`;
    const headers = this.setHeaders();
    const response = await axios.post(
      url,
      {
        transferId: transferID,
        responseNotes,
      },
      { headers }
    );
    return response;
  }

  async rejectTTU({ transferID, responseNotes }) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/wallet/reject`;
    const headers = this.setHeaders();
    const response = await axios.post(
      url,
      {
        transferId: transferID,
        responseNotes,
      },
      { headers }
    );
    return response;
  }

  async getShopifyUrl(shopifyReturnUrl) {
    const url = `${this.baseEndpointUrl}provisionnew/v2/user/shopify-url/generate`;
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    const headers = this.setHeaders();
    const response = await axios.post(
      url,
      {
        token,
        refreshToken,
        shopifyReturnUrl,
      },
      { headers }
    );
    return response;
  }

  async createTransaction(payload) {
    const url = `${this.baseEndpointUrl}triboonew/api/v1.0/partner/transaction`;
    const headers = this.setHeaders();
    const response = await axios.post(url, payload, { headers });
    return response;
  }

  async getExchangeRates() {
    const url = `${this.baseEndpointUrl}provisionnew/v2/currency`;
    const headers = await this.setHeaders();
    const { data } = await axios.get(url, {
      headers,
    });
    return data;
  }
}
