import moment from "moment";
export default {
  filters: {
    convertToLocalMonthDay(regDate) {
      return moment.utc(regDate).format("MMMM DD");
    },
    convertToLocalFullDate(regDate) {
      return moment.utc(regDate).format("MMMM DD, YYYY");
    },
    convertToLocalDateShortDay(regDate) {
      return moment.utc(regDate).format("DD MMMM YYYY");
    },
    convertToLocalDateShortMonth(regDate) {
      return moment.utc(regDate).format("DD MMM. YYYY");
    },
    convertToLocalTime(regDate) {
      return moment.utc(regDate).local().format("h:mm:ss A z");
    },
    convertToLocalFullDateTime(regDate) {
      return moment.utc(regDate).local().format("MMMM DD YYYY, h:mm:ss A z");
    },
  },
};
