import Vue from "vue";
import axios from "axios";
import store from "@/store";
export default class ErrorService {
  constructor() {
    this.seqEndpoint = process.env.VUE_APP_API + "frontlogsrv/Logging/web";
  }

  setHeaders() {
    const token = Vue.helpers.getCookie("loginToken");
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }

  onError({ response }, reqPayload = null) {
    const {
      data,
      status,
      config: { url, method },
    } = response;
    const message = this.formatMessage(data);
    store.commit("wallet/setError", message);
    const payload = reqPayload ? JSON.stringify(reqPayload) : null;
    this.logToSeq({ method, url, status, message, payload });
    this.logToConsole({ method, url, status, message, payload });
  }

  formatMessage(data) {
    if (typeof data === "string") return data;
    if (data.ErrorMessage) return data.ErrorMessage;
    if (data[0] && data[0].errorMessage) return data[0].errorMessage;
  }

  logToSeq({ method, url, status, message, payload }) {
    const headers = this.setHeaders();
    axios.post(
      this.seqEndpoint,
      {
        message: `METHOD: ${method}, URL: ${url}, STATUS: ${status}, MESSAGE: ${message}, REQUEST PAYLOAD/PARAMS: ${payload}`,
        severityLevel: "error",
      },
      { headers }
    );
  }

  logToConsole({ method, url, status, message, payload }) {
    if (process.env.VUE_APP_DEBUG || process.env.NODE_ENV === "development") {
      console.log("API ERROR", method, url, status, message, payload);
    }
  }

  initVueErrorHandling() {
    Vue.config.errorHandler = (err, vm, info) => {
      const componentTag = this._getComponentTag(vm);
      console.error(
        `VUE ERROR: ${err}\nCOMPONENT: ${componentTag}\nINFO: ${info}`
      );
      const headers = this.setHeaders();
      axios.post(
        this.seqEndpoint,
        {
          message: `VUE ERROR: ${err}, COMPONENT: ${componentTag}, INFO: ${info}`,
          severityLevel: "error",
        },
        { headers }
      );
    };
  }

  _getComponentTag(vm) {
    if (!vm) {
      return "[unknown]";
    }

    if (vm.$vnode.tag) {
      return vm.$vnode.tag.replace(/vue-component-\d+-/i, "");
    }

    if (vm.$el.id) {
      return vm.$el.id;
    }
  }
}
