import Api from "./Api";
import axios from "axios";

export default class Permissions extends Api {
  constructor() {
    super();
    this.baseEndpointUrl =
      process.env.VUE_APP_API + "identityadm/v1.0/permissions/";
  }

  async getAuthUserPermissions() {
    const url = `${this.baseEndpointUrl}user`;
    const headers = this.setHeaders();
    const response = await axios.get(url, { headers });
    return response;
  }
}
