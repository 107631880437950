import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Auth from "@/views/Auth";
import Health from "@/views/Health";
import Wallet from "@/views/Wallet";
import Earning from "@/views/Earning";
import Spending from "@/views/Spending";
// import Activities from "@/views/Activities";
import Print from "@/views/Print";

import middlewarePipeline from "./middlewarePipeline";
import token from "./middleware/token";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    meta: {
      public: true,
    },
  },
  {
    path: "/health",
    name: "health",
    component: Health,
    meta: {
      hideNav: true,
      public: true,
    },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
    meta: {
      middleware: [token],
    },
  },
  {
    path: "/earning",
    name: "earning",
    component: Earning,
    meta: {
      middleware: [token],
    },
  },
  {
    path: "/spending",
    name: "spending",
    component: Spending,
    meta: {
      middleware: [token],
    },
  },
  // {
  //   path: "/activities",
  //   name: "activities",
  //   component: Activities,
  //   meta: {
  //     middleware: [token],
  //   },
  // },
  {
    path: "/print",
    name: "print",
    component: Print,
    meta: {
      hideNav: true,
    },
  },
  {
    path: "*",
    redirect: { name: "wallet" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware && !to.matched[0].meta.middleware) {
    return next();
  } else if (to.matched[0].meta.middleware) {
    to.meta.middleware = to.matched[0].meta.middleware;
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
