import Wallet from "@/api/Wallet";
import ErrorHandling from "@/plugins/errorHandling";

const wallet = new Wallet();
const errorHandling = new ErrorHandling();

const getUser = async ({ dispatch, commit, rootGetters }) => {
  commit("setLoading", true);
  try {
    await dispatch("auth/getAuthUser", {}, { root: true });
    const { data } = await wallet.getWallet();

    data.result.authUser = rootGetters["auth/authUser"];
    commit("setUser", data.result);
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("setLoading", false);
};

const setActivityCount = ({ commit }, { resultLength, total }) => {
  const loadMoreCount = total - resultLength < 10 ? total - resultLength : 10;
  const activityCount = resultLength + loadMoreCount;

  commit("setActivityCount", activityCount);
  commit("setActivitiesShowloadMoreBtn", loadMoreCount > 0);
};

const getUserActivity = async ({ commit, dispatch }, payload) => {
  commit("setActivityLoading", true);
  try {
    const { data } = await wallet.getUserActivity(payload);
    if (payload.direction === "income") {
      commit("setUserIncome", data.result);
    } else {
      commit("setUserOutgoing", data.result);
    }

    dispatch("setActivityCount", {
      resultLength: data.result.length,
      total: data.pagination.totalCount,
    });
    commit("setActiveFilter", payload.filter);
    return data;
  } catch (error) {
    dispatch(
      "snackbar/show",
      { text: "Something went wrong!", color: "error" },
      { root: true }
    );
    errorHandling.onError(error);
  } finally {
    commit("setActivityLoading", false);
  }
};

const getUserActivityLast30Days = async ({ commit }, payload) => {
  payload.direction === "income"
    ? commit("setIncome30Loading", true)
    : commit("setOutgoing30Loading", true);
  try {
    const { data } = await wallet.getUserActivityLast30Days(payload);
    if (payload.direction === "income") {
      commit("setUserIncomeLast30Days", data.result);
    } else {
      commit("setUserOutgoingLast30Days", data.result);
    }
  } catch (error) {
    dispatch(
      "snackbar/show",
      { text: "Something went wrong!", color: "error" },
      { root: true }
    );
    errorHandling.onError(error);
  } finally {
    commit("setIncome30Loading", false);
    commit("setOutgoing30Loading", false);
  }
};

const getFilters = async ({ commit }, filterType) => {
  commit("setLoading", true);
  try {
    const { data } = await wallet.getFilters(filterType);
    if (filterType === "income") {
      commit("setIncomeFilters", data.result);
    } else {
      commit("setOutgoingFilters", data.result);
    }
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("setLoading", false);
};

const getWalletById = async ({ commit }, walletId) => {
  commit("setLoading", true);
  try {
    const { data } = await wallet.getWalletById(walletId);
    return data;
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("setLoading", false);
};

const copyWalletId = async ({ commit, dispatch }, walletId) => {
  commit("setLoading", true);
  try {
    navigator.clipboard.writeText(walletId);
    dispatch("snackbar/show", { text: "Copying successful!" }, { root: true });
  } catch (error) {
    errorHandling.onError(error);
    dispatch(
      "snackbar/show",
      { text: "Copying not successful!" },
      { root: true }
    );
  }
  commit("setLoading", false);
};

const sendTTUToWallet = async ({ commit }, payload) => {
  commit("setError", "");
  commit("sendTTULoading", true);
  try {
    const { data } = await wallet.sendTTUToWallet(payload);
    commit("setTTUToWallet", data);
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("sendTTULoading", false);
};

const acceptTTU = async ({ commit }, payload) => {
  commit("acceptTTULoading", true);
  try {
    const { data } = await wallet.acceptTTU(payload);
    commit("setAcceptTTU", data);
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("acceptTTULoading", false);
};

const rejectTTU = async ({ commit }, payload) => {
  commit("rejectTTULoading", true);
  try {
    const { data } = await wallet.rejectTTU(payload);
    commit("setRejectTTU", data);
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("rejectTTULoading", false);
};

const getWalletOwner = async ({ commit }, walletId) => {
  commit("setLoading", true);
  try {
    const { data } = await wallet.getWalletOwner(walletId);
    commit("setWalletOwner", data.result);
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("setLoading", false);
};

const getECommerceUrl = async ({ commit }, { callback }) => {
  commit("setLoading", true);
  try {
    const { data } = await wallet.getShopifyUrl(callback);

    return data.payload.shopifyUrl;
  } catch (error) {
    errorHandling.onError(error);
  }
  commit("setLoading", false);
};

const getTransferReceiverByTtuId = async ({ commit }, ttuId) => {
  commit("setLoading", true);
  try {
    const { data } = await wallet.getWalletForPartner(ttuId);

    if (data.result) {
      commit("setTransferReceiver", data.result);
      return true;
    }
  } catch (error) {
    errorHandling.onError(error);
    return false;
  }
  commit("setLoading", false);
};

const setTransferReceiverUsername = async ({ commit }, username) => {
  commit("setTransferReceiverUsername", username);
};

const setTransferReceiverDebitAmount = async ({ commit }, debitAmount) => {
  commit("setTransferReceiverDebitAmount", debitAmount);
};

const createTransaction = async ({ commit }, payload) => {
  commit("setError", "");
  commit("sendTTULoading", true);
  let success = false;
  try {
    const response = await wallet.createTransaction(payload);
    success = response.status === 200;
  } catch (error) {
    // errorHandling.onError(error);
  } finally {
    commit("sendTTULoading", false);
    return success;
  }
};

const getExchangeRates = async function ({ commit }) {
  try {
    commit("setExchangeRatesError", false);
    commit("setExchangeRatesLoading", true);
    const data = await wallet.getExchangeRates();
    commit("setExchangeRates", data[0].results);
    commit("setExchangeRatesLoading", false);
    return data[0].results;
  } catch (error) {
    commit("setExchangeRatesLoading", false);
    commit("setExchangeRatesError", true);
    errorHandling.onError(error);
    return false;
  }
};

export default {
  getUser,
  getUserActivity,
  getUserActivityLast30Days,
  getFilters,
  getWalletById,
  copyWalletId,
  sendTTUToWallet,
  acceptTTU,
  rejectTTU,
  getECommerceUrl,
  getWalletOwner,
  setActivityCount,
  getTransferReceiverByTtuId,
  setTransferReceiverUsername,
  setTransferReceiverDebitAmount,
  createTransaction,
  getExchangeRates,
};
