import axios from "axios";

export default () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        window.location.href = `${process.env.VUE_APP_SSO}?callback=${process.env.VUE_APP_DOMAIN}auth`;
      }
      return Promise.reject(error);
    }
  );
};
