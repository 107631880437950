import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  user: null,
  userIncome: [],
  userOutgoing: [],
  userIncomeLast30Days: [],
  userOutgoingLast30Days: [],
  incomeFilters: [],
  outgoingFilters: [],
  walletOwner: null,
  TTUToWallet: null,
  currentDateFilter: 30,
  acceptTTU: null,
  rejectTTU: null,
  loading: false,
  activityLoading: true,
  income30Loading: true,
  outgoing30Loading: true,
  error: "",
  activityCount: 10,
  activitiesShowloadMoreBtn: false,
  activeFilter: "",
  sendTTULoading: false,
  acceptTTULoading: false,
  rejectTTULoading: false,
  transferReceiver: null,
  exchangeRates: null,
  exchangeRatesError: false,
  exchangeRatesLoading: false,
  showQRModal: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
