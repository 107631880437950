import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  authUser: null,
  loading: false,
  authUserPermissions: null,
  permissionsLoading: false,
  donations: {},
  donationsLoading: false,
  donationsPercentage: {},
  donationsPercentageLoading: false,
  location: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
