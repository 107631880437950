import Vue from "vue";
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/sass/variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        appBcg: "#fff",
        primary: "#222",
        secondary: "#808081",
        lightgrey: "#f7f7f7",
        border: "#e7e7e7",
        btnBorder: "#c1c1c1",
        pendingBck: "#fff",
        silver: "#C0BFBF",
      },
      dark: {
        appBcg: "#222",
        primary: "#fff",
        secondary: "#808081",
        lightgrey: "#282829",
        border: "#2e2f30",
        btnBorder: "#eee",
        pendingBck: "#282829",
        silver: "#C0BFBF",
      },
    },
  },
});
