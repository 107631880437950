<template>
  <v-app-bar app flat color="appBcg" class="app-bar">
    <t-dialog-wrapper
      v-if="isCashier && cashierDialogOpen && !loading"
      :show="cashierDialogOpen"
      :show-close="false"
      width="335"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
      :style="{ borderRadius: $vuetify.breakpoint.smAndDown ? '0px' : '' }"
      @close-dialog="cashierDialogOpen = false"
    >
      <cashier-transfer-dialog-wrapper
        :user="user"
        @closeDialog="cashierDialogOpen = false"
      ></cashier-transfer-dialog-wrapper>
    </t-dialog-wrapper>
    <div class="d-flex flex-column align-stretch flex-grow-1">
      <div class="d-flex align-center">
        <div>
          <router-link to="/wallet">
            <v-img width="60" height="24" :src="require('@/assets/logo.svg')" />
          </router-link>
        </div>
        <v-divider vertical class="mx-5 my-4" />
        <div class="d-flex align-center py-3 app-bar__wallet">
          <div class="mr-4">
            <v-img
              width="30"
              height="30"
              :src="require('@/assets/ttuToken.svg')"
            />
          </div>
          <div>
            <div>
              <block-text
                :text="$t('header.myWallet')"
                color="secondary"
                type="caption"
              />
            </div>
            <div>
              <block-text
                v-if="user"
                :text="user.balance.toFixed(2)"
                weight="bold"
              />
            </div>
          </div>
        </div>
        <div v-if="isCashier && !loading" class="ml-6">
          <v-btn
            :color="$vuetify.theme.dark ? '#fff' : '#222'"
            large
            plain
            class="rounded-lg body-1 bold px-5 py-0 primary-btn"
            depressed
            outlined
            :height="40"
            :width="$vuetify.breakpoint.xs ? '50' : '140'"
            @click="cashierDialogOpen = true"
          >
            <ph-qr-code :size="24" color="green" />
            <span v-show="$vuetify.breakpoint.smAndUp" class="pl-2">{{
              $t("header.scanQrCode")
            }}</span>
          </v-btn>
        </div>

        <v-spacer></v-spacer>
        <profile-menu v-if="user" />
      </div>
      <v-divider />
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { PhQrCode } from "phosphor-vue";
export default {
  name: "AppBar",
  components: {
    ProfileMenu: () => import("@/components/ProfileMenu"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    CashierTransferDialogWrapper: () =>
      import("@/components/Dialogs/CashierTransferDialogWrapper"),
    PhQrCode,
  },
  data() {
    return {
      cashierDialogOpen: false,
    };
  },
  watch: {
    showQRModal(value) {
      this.cashierDialogOpen = value;
    },
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      userPermissions: "auth/authUserPermissions",
      loading: "auth/permissionsLoading",
      showQRModal: "wallet/showQRModal",
    }),
    isCashier() {
      return this.userPermissions
        ? this.userPermissions?.result?.find(
            (item) => item.permissionName === "mm-partner-transaction-create"
          )
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  ::v-deep .v-toolbar__content {
    padding-bottom: 0;
    padding-top: 0;
  }
  &__wallet {
    border-bottom: 1px solid #222;
  }
}
</style>
