export default {
  methods: {
    formatActivities(activities) {
      const formatted = [];

      if (activities) {
        activities.forEach((activity, index) => {
          let activityStatus = "";
          switch (activity.transferStatus) {
            case 0:
              activityStatus = "Completed";
              break;
            case 1:
              activityStatus = "Pending";
              break;
            case 2:
              activityStatus = "Rejected";
              break;
            case 3:
              activityStatus = "Expired";
              break;
            default:
              activityStatus = "Completed";
          }

          const pickedActivity = {
            user: activity.otherName,
            date: activity.date,
            dateTime: activity.dateTime,
            status: activityStatus,
            type: activity.filterGroup,
            amount: activity.activityAmount,
            direction: activity.direction,
            walletId: activity.walletId,
            otherWalletId: activity.otherWalletId,
            transactionRequestNote: activity.transferRequestNotes,
            transactionHeaderID: activity.transactionHeaderId,
            userTransferID: activity.userTransferId,
            userImage: activity.profileImageUrl,
            index: index,
            active: false,
            hoverOn: false,
            hoverOff: false,
          };

          formatted.push(pickedActivity);
        });
      }
      return formatted;
    },
  },
};
